import Head from 'next/head';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Root, Portal } from '@radix-ui/react-dropdown-menu';

import LockinIcon from '../public/images/Lock-In_Icon.svg';
import AreasSnapshot from '../public/images/Areas_Snapshot.png';
import ProjectsSnapshot from '../public/images/Project_Snapshot.png';
import IssuesSnapshot from '../public/images/Issues_Snapshot.png';
import InventorySnapshot from '../public/images/Inventory_Snapshot.png';

import PricingTable from '../components/PricingTable';
import Footer from '../components/Footer';
import { IconButton } from '../components/Button';
import {
  StyledDropdownTrigger,
  StyledDropdownContent,
  StyledDropdownItem,
} from '../components/UtilityStyle';

import LandingFeatureBlock from '../components/LandingFeatureBlock';
import TestimonialQuote from '../components/LandingPage/TestimonialQuote';
import Section from '../components/LandingPage/Section';

import styles from '../styles/Landing.module.scss';

const Landing = () => {
  const router = useRouter();
  return (
    <div className={styles.landing_body}>
      <Head>
        <title>Minicastle - Easy Maintenance & Home Improvement Tracking</title>
        <meta
          name="description"
          content="Minicastle is the easiest and friendliest issue, project, and inventory tracking tool for homeowners. Fix problems faster, enjoy life more."
        ></meta>
      </Head>
      {/* <div className={styles.redirect_banner}>
        MESSAGE PLACEHOLDER
      </div> */}
      <nav className={styles.landing_nav}>
        <a>
          <Image
            src={'/Minicastle_Logo.svg'}
            width={178}
            height={32}
            alt="Minicastle Logo"
          />
        </a>
        <div className={styles.show_on_mobile}>
          <Root>
            <StyledDropdownTrigger>
              <IconButton icon="menu"></IconButton>
            </StyledDropdownTrigger>
            <Portal>
              <StyledDropdownContent align="end" sideOffset={4}>
                <StyledDropdownItem onClick={() => router.push('/#features')}>
                  Features
                </StyledDropdownItem>
                <StyledDropdownItem
                  onClick={() => window.open('https://docs.minicastle.app')}
                >
                  Docs
                </StyledDropdownItem>
                <StyledDropdownItem onClick={() => router.push('/#pricing')}>
                  Pricing
                </StyledDropdownItem>
                <StyledDropdownItem onClick={() => router.push('/login')}>
                  Login
                </StyledDropdownItem>
              </StyledDropdownContent>
            </Portal>
          </Root>
        </div>
        <div className={styles.show_on_desktop}>
          <div className={styles.landing_nav_items}>
            <ul>
              <li>
                <a href="#features">Features</a>
              </li>
              <li>
                <a
                  className="plausible-event-name=Docs+Click"
                  href="https://docs.minicastle.app"
                >
                  Docs
                </a>
              </li>
              <li>
                <a href="#pricing">Pricing</a>
              </li>
              <li>
                <Link href={'/login'}>
                  <strong> Log in</strong>
                </Link>
              </li>
            </ul>
          </div>{' '}
        </div>
      </nav>
      <main>
        <section className={styles.intro_section}>
          <h1>
            Build, fix, upgrade,
            <br /> and enjoy your home.
          </h1>
          <p>
            Minicastle helps{' '}
            <strong style={{ color: '#9747FF' }}>fearless homeowners</strong>{' '}
            easily track issues, DIY projects, maintenance, and more.
          </p>
          <Link
            className={`${styles.landing_button} plausible-event-name=Banner+Get+Started+Button+Click`}
            href="/login"
            style={{
              marginTop: '1rem',
              marginBottom: '1rem',
              background: '#9747FF',
              color: 'white',
            }}
          >
            Get Started
          </Link>
          <div className={styles.show_on_mobile}>
            <Image
              src={'/Intro--mobile.png'}
              width={323}
              height={484}
              style={{
                marginTop: '1.25rem',
              }}
              alt="Mobile app preview"
            />
          </div>
          <div className={styles.show_on_desktop}>
            <Image
              src={'/Intro--desktop.png'}
              width={900}
              height={537}
              style={{
                width: '61.25rem',
                height: '33.5625rem',
                marginTop: '1.5rem',
              }}
              alt="Desktop app preview"
            />
          </div>
        </section>
        <section className={styles.feature_section} id={'features'}>
          <div className={styles.primary_features}>
            <LandingFeatureBlock
              name="Issues"
              title="Build and Improve Anything"
              type="primary"
              description={
                'Whether you need to install, fix, replace, paint, or clean, Issues are here to help you get the job done.'
              }
              image={IssuesSnapshot}
            />
            <LandingFeatureBlock
              name="Areas"
              title="Prioritize & Modularize"
              type="primary"
              description={
                'Struggling to remember where you left off? Areas automatically organize your projects, priorities, and stuff into a single view.'
              }
              image={AreasSnapshot}
            />
            <LandingFeatureBlock
              name="Projects"
              title="Turn Ideas into Reality"
              type="primary"
              description={
                'Plan ambitiously, then take stock of what you accomplish. Projects bring your vision to life.'
              }
              image={ProjectsSnapshot}
            />
            <LandingFeatureBlock
              name="Inventory"
              title="Organize at Any Level"
              type="primary"
              description={
                'Your equipment, appliances, systems, and stuff are all valuable tools. Track relationships, costs, quantities, and more.'
              }
              image={InventorySnapshot}
            />
          </div>
          <div>
            <div className={styles.secondary_features}>
              <h2>Collaborate & Coordinate</h2>
              <LandingFeatureBlock
                title="Co-Owners"
                description={
                  'Make it a party: invite unlimited roommates, partners, spouses, or friends to help manage anything.'
                }
              />
              <LandingFeatureBlock
                title="Calendar View"
                description={
                  'Plan your weekends, or give yourself a deadline to finally get that project done.'
                }
              />
              <h2>Get Organized</h2>
              <LandingFeatureBlock
                title="Links"
                description={
                  'Help your future self by saving contextual details. Link to files, photos, receipts, manuals, and budgets.'
                }
              />
              <LandingFeatureBlock
                title="Suggestions"
                description={
                  'Not sure where to start? Discover suggestions as you work, or check out our guides for even more resources.'
                }
              />
              <h2>Take Control</h2>
              <LandingFeatureBlock
                title="Reporting"
                description={
                  'You’ve already accomplished more than you know! Use reports to enjoy your progress as much as your plans.'
                }
              />
              <LandingFeatureBlock
                title="Weekly Digests"
                description={
                  'Get automated reminders about everything going on with your house.'
                }
              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 4,
              marginTop: '5rem',
            }}
          >
            <Image src={LockinIcon} width={40} height={40} alt="" />
            <h3 style={{ margin: '1rem' }}>No Lock-In</h3>
          </div>
          <h2 style={{ color: 'white', margin: 0 }}>
            Export your data at any time
          </h2>
        </section>
        <section className={styles.comparison_section}>
          <h2>Purpose-built for the job</h2>
          <div className={styles.comparison_section_accordions}>
            <details
              className={'plausible-event-name=Spreadsheets+Compare+Click'}
            >
              <summary>Minicastle vs. Spreadsheets</summary>
              <ul>
                <li>
                  <b>Structured & Connected:</b> Seamlessly view issues,
                  inventory, and projects by area, status, and more. Don't waste
                  time creating complicated logic to organize and link your
                  data.
                </li>
                <li>
                  <b>Effortlessly Collaborative:</b> Share with anyone, and
                  don't worry about finding or remembering specific links and
                  spreadsheet names. Everything is easy to find and visible in a
                  simple, concise interface.
                </li>
                <li>
                  <b>Phone Friendly:</b> Spreadsheets are painful to use when
                  you're away from your desk. Access your data on the move with
                  a mobile-friendly interface. No app download required.
                </li>
                <li>
                  <b>Purpose Built:</b> No need to re-invent the wheel! Enjoy
                  software designed to help you get things done faster and
                  better.
                </li>
              </ul>
            </details>
            <details className={'plausible-event-name=PM+Compare+Click'}>
              <summary>Minicastle vs. Project Management Apps</summary>
              <ul>
                <li>
                  <b>Purpose Built:</b> We prioritize features, functionality,
                  and experiences for homeowners. We shouldn't try to be
                  everything to everyone.
                </li>
                <li>
                  <b>Easy to Learn:</b> No learning curve or template-tweaking
                  required. Designed to get you up-and-running in minutes.
                </li>
                <li>
                  <b>Organized by Area:</b> The easiest way to understand what's
                  going on your house is to see issues room-by-room. Get
                  area-based views with no additional setup.
                </li>
                <li>
                  <b>No Downloads:</b> No more app downloads or updates. Enjoy
                  an always fresh, mobile-friendly experience on the go, or a
                  more spacious experience on your desktop.
                </li>
              </ul>
            </details>
            <details className={'plausible-event-name=Todos+Compare+Click'}>
              <summary>Minicastle vs. Todo Lists</summary>
              <ul>
                <li>
                  <b>Focused & Purpose-Built:</b> Your to-do list is full of all
                  kinds of tasks and reminders, not just house work. Improve
                  your home faster with a clear, concise list of action items
                  for your house.
                </li>
                <li>
                  <b>Organized:</b> View issues and work by area, date, status,
                  and more. All you have to do is walk into a room, and you'll
                  know exactly what you want to work on.
                </li>
                <li>
                  <b>Collaborative:</b> Instantly share with partners, spouses,
                  roommates, and any one else that shares your home.
                </li>
                <li>
                  <b>Help Your Future Self:</b> To-do items disappear as soon as
                  they're complete. Use completed issues to help you solve
                  problems in the future, especially by adding notes, links,
                  images, cost information, and more.
                </li>
              </ul>
            </details>
            <details className={'plausible-event-name=Binders+Compare+Click'}>
              <summary>Minicastle vs. Notebooks & Binders</summary>
              <ul>
                <li>
                  <b>Instantly Available:</b> Your information is available
                  wherever you are. No need to go ruffling through closets,
                  drawers, or notebooks for random pieces of paper.
                </li>
                <li>
                  <b>Organized & Efficient:</b> Find what you need with just a
                  few clicks, keywords, or less. Issues are immediately
                  organized by area, time, status, and more.
                </li>
                <li>
                  <b>Collaborative:</b> Instantly share with partners, spouses,
                  roommates, and anyone else that shares your home - from
                  anywhere. They don't need to remember anything other than
                  their email.
                </li>
              </ul>
            </details>
            <details className={'plausible-event-name=Other+Compare+Click'}>
              <summary>Minicastle vs. Other Home Apps</summary>
              <ul>
                <li>
                  <b>Built just for homeowners:</b> We're not here to sell you
                  ads, handyman services, home insurance, a new roof, or another
                  house. Our app is built just for you and what you actually
                  need. We have no customers other than homeowners.
                </li>
                <li>
                  <b>All-in-one:</b> We hope to be the only tool you need to
                  manage your home. No more bouncing between apps, spreadsheets,
                  and notebooks.
                </li>
                <li>
                  <b>In it for the long-run:</b> We use Minicastle to manage our
                  own home. As much as we enjoyed life before buying a home, our
                  goal is to keep owning a home (or two) for the next 60+ years
                  until we drop dead or move to space. Our plan is to use
                  Minicastle for the whole ride.
                </li>
              </ul>
            </details>
          </div>
        </section>
        <section className={styles.suggestions_section}>
          <div>
            <svg
              title="Suggestions icon!"
              xmlns="http://www.w3.org/2000/svg"
              width="56"
              height="56"
              viewBox="0 0 33 33"
              fill="none"
            >
              <path
                d="M23.04 12.9822L24.3275 10.1497L27.16 8.86219L24.3275 7.57469L23.04 4.74219L21.7525 7.57469L18.92 8.86219L21.7525 10.1497L23.04 12.9822ZM15.315 13.4972L12.74 7.83219L10.165 13.4972L4.5 16.0722L10.165 18.6472L12.74 24.3122L15.315 18.6472L20.98 16.0722L15.315 13.4972ZM23.04 19.1622L21.7525 21.9947L18.92 23.2822L21.7525 24.5697L23.04 27.4022L24.3275 24.5697L27.16 23.2822L24.3275 21.9947L23.04 19.1622Z"
                fill="#FF8B5A"
              />
            </svg>
            <h2>
              Home Improvement is hard.
              <br /> Let’s help you figure out what’s next.
            </h2>
          </div>

          <p>
            Start with our interactive guides, or access{' '}
            <strong>Suggestions</strong> directly as you work.
          </p>
          <Link
            className={`${styles.suggestions_section_cta} plausible-event-name=Landing+Page+Homeowners+Guide+Click`}
            href="/guides/homeowners-guide"
            target="_blank"
          >
            Homeowners Guide 2024
          </Link>
        </section>
        <Section>
          <h2>Used by hundreds of homeowners, condo-owners, and renters.</h2>
          <TestimonialQuote />
        </Section>
        <Section name="pricing">
          <h2
            style={{
              color: 'white',
            }}
          >
            Grow and invest in your home, skills, or business.
            <br />
            Start today for free.
          </h2>
          <Link
            className={`${styles.landing_button} plausible-event-name=Banner+Get+Started+Button+Click`}
            href="/login"
            style={{
              marginTop: '1rem',
              marginBottom: '6rem',
              background: '#9747FF',
              color: 'white',
            }}
          >
            Get Started
          </Link>
          <PricingTable />
        </Section>
      </main>
      <Footer />
    </div>
  );
};

export default Landing;
